export const WINDOWS_MT4_DOWNLOAD_LINK = 'https://download.mql5.com/cdn/web/13193/mt4/jmfinancial24setup.exe'
export const ANDROID_MT4_DOWNLOAD_LINK =
  'https://play.google.com/store/apps/details?id=net.metaquotes.metatrader4&hl=en&referrer=ref_id%253d5188595229970786286%2526utm_source%253dwww.metatrader4.com%2526utm_campaign%253ddownload'
export const APPLE_MT4_DOWNLOAD_LINK = 'https://apps.apple.com/kw/app/metatrader-4/id496212596'

export const WINDOWS_MT5_DOWNLOAD_LINK =
  'https://download.mql5.com/cdn/web/inno.net.solutions/mt5/innonetsolutions5setup.exe'
export const ANDROID_MT5_DOWNLOAD_LINK =
  'https://play.google.com/store/apps/details?id=net.metaquotes.metatrader5&hl=en'
export const APPLE_MT5_DOWNLOAD_LINK = 'https://apps.apple.com/kw/app/metatrader-5/id413251709'
